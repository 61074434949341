// src/components/CartItemLoader.js
import React from 'react';
import Loader from './Loader';

const CartItemLoader = ({ theme }) => (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 rounded-lg">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-brand-500"></div>
    </div>
);


export default CartItemLoader;
