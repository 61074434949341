// src/pages/CartPage.js
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeContext, ThemeContextData } from '../../contexts/ThemeContext';
import { getCart, createOrder, verifyPayment, updateCartItemQuantity, removeCartItem } from '../../utils/api';
import { loadRazorpay } from '../../utils/razorpay';
import Loader from '../../components/Loader';
import { FaShoppingCart, FaTrash, FaMinus, FaPlus } from 'react-icons/fa';
import CartItemLoader from '../../components/CartItemLoader';

const CartPage = () => {
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatingItems, setUpdatingItems] = useState({});
  const [error, setError] = useState(null);
  const themeContext = useContext(ThemeContext);
  const theme = ThemeContextData[themeContext];
  const navigate = useNavigate();

  useEffect(() => {
    fetchCart();
  }, []);

  const fetchCart = async () => {
    try {
      const cartData = await getCart();
      setCart(cartData);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch cart. Please try again.');
      setLoading(false);
    }
  };

  const handleCheckout = async () => {
    try {
      const order = await createOrder(calculateTotal());
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: order.currency,
        name: 'Your Company Name',
        description: 'Course Purchase',
        order_id: order.id,
        handler: async (response) => {
          try {
            await verifyPayment({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });
            navigate('/');
          } catch (error) {
            console.error('Payment verification failed:', error);
            navigate('/');
          }
        },
        prefill: {
          name: 'User Name',
          email: 'user@example.com',
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp = await loadRazorpay();
      const paymentObject = new rzp(options);
      paymentObject.open();
    } catch (error) {
      console.error('Checkout failed:', error);
      setError('Checkout failed. Please try again.');
    }
  };

  const calculateTotal = () => {
    return cart.items.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleQuantityChange = async (itemId, newQuantity) => {
    try {
      setUpdatingItems(prev => ({ ...prev, [itemId]: true }));
      if (newQuantity < 1) {
        await removeCartItem(itemId);
      }
      await updateCartItemQuantity(itemId, newQuantity);
      await fetchCart();
    } catch (err) {
      setError('Failed to update quantity. Please try again.');
    } finally {
      setUpdatingItems(prev => ({ ...prev, [itemId]: false }));
    }
  };

  const handleRemoveItem = async (itemId) => {
    try {
      setUpdatingItems(prev => ({ ...prev, [itemId]: true }));
      await removeCartItem(itemId);
      fetchCart();
    } catch (err) {
      setError('Failed to remove item. Please try again.');
    } finally {
      setUpdatingItems(prev => ({ ...prev, [itemId]: false }));
    }
  };

  if (loading) {
    return <Loader theme={theme} />;
  }

  if (error) {
    return <div className={`${theme.background} ${theme.textNormal} p-4`}>{error}</div>;
  }

  return (
    <div className={`${theme.background} ${theme.textNormal} min-h-screen p-4 md:p-8`}>
      <h1 className={`${theme.textFocus} text-3xl font-bold mb-6 flex items-center`}>
        <FaShoppingCart className="mr-2" /> Your Cart
      </h1>
      {cart && cart.items.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-2 space-y-4">
            {cart.items.map((item) => (
              <div key={item._id} className={`${theme.backgroundSecondary} p-4 rounded-lg shadow-md flex flex-col sm:flex-row items-center sm:items-start relative ${updatingItems[item.item] ? 'opacity-50' : ''}`}>
                {updatingItems[item.item] && <CartItemLoader theme={theme} />}
                {item.thumbnail && (
                  <img src={item.thumbnail} alt={item.title} className="w-full sm:w-32 h-32 object-cover rounded mb-4 sm:mb-0 sm:mr-4" />
                )}
                <div className="flex-grow">
                  <h2 className={`${theme.textFocus} text-xl font-semibold mb-2`}>{item.title}</h2>
                  <p className={`${theme.textNormal} mb-1`}>Price: ₹{item.price}</p>
                  <p className={`${theme.textNormal} mb-1`}>Type: {item.itemType}</p>
                  {item.itemType === 'Course' && (
                    <p className={`${theme.textNormal} mb-2`}>
                      Instructor: {item.instructor}
                    </p>
                  )}
                  <div className="flex items-center mt-2">
                    <button
                      onClick={() => handleQuantityChange(item.item, item.quantity - 1)}
                      className={`${theme.buttonSecondary} p-1 rounded`}
                      disabled={updatingItems[item.item]}
                    >
                      <FaMinus />
                    </button>
                    <span className={`${theme.textNormal} mx-2`}>{item.quantity}</span>
                    <button
                      onClick={() => handleQuantityChange(item.item, item.quantity + 1)}
                      className={`${theme.buttonSecondary} p-1 rounded`}
                      disabled={updatingItems[item.item]}
                    >
                      <FaPlus />
                    </button>
                    <button
                      onClick={() => handleRemoveItem(item.item)}
                      className={`${theme.buttonDanger} ml-4 p-2 rounded`}
                      disabled={updatingItems[item.item]}
                    >
                      <FaTrash />
                    </button>
                  </div>
                  <Link to={`/${item.itemType.toLowerCase()}s/${item.item}`} className={`${theme.textNormalHover} underline mt-2 inline-block`}>
                    View Details
                  </Link>
                </div>
              </div>
            ))}

          </div>
          <div className="md:col-span-1">
            <div className={`${theme.backgroundSecondary} p-6 rounded-lg shadow-md`}>
              <h2 className={`${theme.textFocus} text-2xl font-bold mb-4`}>Order Summary</h2>
              <div className={`${theme.textNormal} mb-4`}>
                <p className="flex justify-between"><span>Subtotal:</span> <span>₹{calculateTotal()}</span></p>
                <p className="flex justify-between"><span>Tax:</span> <span>₹0</span></p>
              </div>
              <div className={`${theme.textFocus} text-xl font-bold mb-4 flex justify-between`}>
                <span>Total:</span> <span>₹{calculateTotal()}</span>
              </div>
              <button
                onClick={handleCheckout}
                className="flex items-center mt-auto text-white bg-brand-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-brand-600 rounded font-bold"
              >
                Proceed to Checkout
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${theme.backgroundSecondary} p-8 rounded-lg shadow-md text-center`}>
          <p className={`${theme.textNormal} text-xl mb-4`}>Your cart is empty.</p>
          <Link
            to="/courses"
            className="inline-block px-6 py-3 mt-auto w-full md:w-auto text-center text-lg font-semibold text-white bg-brand-500 hover:bg-brand-600 rounded-md focus:outline-none"
          >
            Browse Courses
          </Link>
        </div>
      )}
    </div>
  );
};

export default CartPage;
