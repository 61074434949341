import React, { useContext, useState } from 'react';
import { ThemeContext, ThemeContextData } from '../contexts/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPlay, FaFileAlt, FaDownload, FaQuestionCircle, FaChevronDown } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const CourseContent = ({ section, subsection }) => {
  const themeContext = useContext(ThemeContext);
  const theme = ThemeContextData[themeContext];
  const [isOpen, setIsOpen] = useState(true);

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const dropdownVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' },
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const renderContent = () => {
    const content = (() => {
      switch (subsection.type) {
        case 'video':
          return (
            <div className="relative" style={{ paddingTop: '56.25%' }}>
              <iframe
                src={`${subsection.content}?controls=0`}
                title="Video content"
                className="absolute top-0 left-0 w-full h-full"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          );
        case 'text':
          return <div className={`${theme.textNormal} prose max-w-none`}>{subsection.content}</div>;
        case 'file':
          return (
            <a
              href={subsection.url}
              download
              className={`${theme.buttonPrimary} inline-block px-4 py-2 rounded-md transition-colors duration-200`}
            >
              Download File
            </a>
          );
        case 'quiz':
          return <div className={`${theme.textNormal}`}>Quiz component (to be implemented)</div>;
        default:
          return <div className={`${theme.textNormal}`}>Unsupported content type</div>;
      }
    })();

    const icon = (() => {
      switch (subsection.type) {
        case 'video': return <FaPlay className="inline-block mr-2" />;
        case 'text': return <FaFileAlt className="inline-block mr-2" />;
        case 'file': return <FaDownload className="inline-block mr-2" />;
        case 'quiz': return <FaQuestionCircle className="inline-block mr-2" />;
        default: return null;
      }
    })();

    return (
      <motion.div
        className={`${theme.backgroundSecondary} rounded-lg overflow-hidden shadow-lg mb-6`}
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5 }}
      >
        {content}
        <div className={`${theme.backgroundSecondary} p-4`}>
          <div
            className={`${theme.textFocus} text-lg font-semibold mb-2 flex justify-between items-center cursor-pointer`}
            onClick={toggleDropdown}
          >
            <div className="flex items-center">
              {icon}
              <span>{subsection.title}</span>
            </div>
            <motion.div
              animate={{ rotate: isOpen ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <FaChevronDown />
            </motion.div>
          </div>
          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.div
                key="content"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={dropdownVariants}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <div className={`${theme.textNormal} mt-2 pl-6`}>
                  <ReactMarkdown 
                    remarkPlugins={[remarkGfm]}
                    components={{
                      p: ({ node, ...props }) => <p className="mb-2" {...props} />,
                      h1: ({ node, ...props }) => <h1 className={`${theme.textFocus} text-2xl font-bold mb-2`} {...props} />,
                      h2: ({ node, ...props }) => <h2 className={`${theme.textFocus} text-xl font-bold mb-2`} {...props} />,
                      h3: ({ node, ...props }) => <h3 className={`${theme.textFocus} text-lg font-bold mb-2`} {...props} />,
                      ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-2" {...props} />,
                      ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-2" {...props} />,
                      li: ({ node, ...props }) => <li className="mb-1" {...props} />,
                      a: ({ node, ...props }) => <a className={`${theme.textFocus} underline`} {...props} />,
                      code: ({ node, inline, ...props }) => 
                        inline ? (
                          <code className={`${theme.backgroundTertiary} px-1 rounded`} {...props} />
                        ) : (
                          <code className={`${theme.backgroundTertiary} block p-2 rounded mb-2`} {...props} />
                        ),
                    }}
                  >
                    {subsection.description}
                  </ReactMarkdown>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <motion.h2
        className={`${theme.textFocus} text-2xl md:text-3xl font-bold mb-6`}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {section.title}
      </motion.h2>
      <motion.h3
        className={`${theme.textNormalHover} text-xl md:text-2xl mb-4`}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {subsection.title}
      </motion.h3>
      {renderContent()}
    </div>
  );
};

export default CourseContent;
