import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { ThemeContext, ThemeContextData } from '../contexts/ThemeContext';
import { Fragment } from 'react';

const UserMenu = ({ user, logout }) => {
  const themeContext = useContext(ThemeContext);
  const theme = ThemeContextData[themeContext];

  const handleSignOut = () => {
    logout();
    window.location.href = '/';
  };

  const menuItems = [
    { label: 'Profile', to: '/profile' },
    { label: 'Cart', to: '/cart' },
    { label: 'Sign out', onClick: handleSignOut },
  ];

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`${
              open ? '' : 'text-opacity-90'
            } ${theme.textNormal} group px-3 py-2 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span>{user.name}</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 origin-top-right sm:px-0">
              <div className={`overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ${theme.backgroundSecondary}`}>
                <div className="relative grid gap-2 p-4">
                  {menuItems.map((item, index) => (
                    item.to ? (
                      <Link
                        key={index}
                        to={item.to}
                        className={`p-3 flex items-center rounded-lg transition ease-in-out duration-150 ${theme.textNormal} hover:${theme.backgroundHover}`}
                        onClick={close}
                      >
                        <p className="text-sm font-medium">
                          {item.label}
                        </p>
                      </Link>
                    ) : (
                      <button
                        key={index}
                        onClick={() => {
                          item.onClick();
                          close();
                        }}
                        className={`p-3 flex items-center rounded-lg transition ease-in-out duration-150 ${theme.textNormal} hover:${theme.backgroundHover} w-full text-left`}
                      >
                        <p className="text-sm font-medium">
                          {item.label}
                        </p>
                      </button>
                    )
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default UserMenu;
