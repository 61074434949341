import './App.css';
import { AppContext, AppContextData } from '../contexts/AppContext';
import { ThemeContext } from '../contexts/ThemeContext';
import Layout from '../components/layout/Layout';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigation
} from "react-router-dom";
import React from 'react';
import ErrorPage from '../pages/errorPage/ErrorPage';
import HomePage from '../pages/homePage/HomePage';
import ContactUsPage from '../pages/contactUsPage/ContactUsPage';
import ProgramsPage from '../pages/programsPage/ProgramsPage';
import AboutUsPage from '../pages/aboutUsPage/AboutUsPage';
import ProductsPage from '../pages/productsPage/ProductsPage';
import TermsAndConditionsPage from '../pages/termsAndConditionsPage/TermsAndConditionsPage';
import ProgramPage from '../pages/programsPage/ProgramPage';
import SignInPage from '../pages/authPage/SignInPage';
import SignUpPage from '../pages/authPage/SignUpPage';
import BlogsPage from '../pages/blogsPage/BlogsPage';
import BlogPage from '../pages/blogsPage/BlogPage';
import { LIGHT_THEME, DARK_THEME } from '../constants/ThemeConstants';
import CoursesPage from '../pages/coursePage/CoursesPage';
import { AuthProvider } from '../contexts/AuthContext';
import ProfilePage from '../pages/profilePage/ProfilePage';
import CoursePage from '../pages/coursePage/CoursePage';
import CartPage from '../pages/cartPage/CartPage';
import Loader from '../components/Loader';
import { ThemeContextData } from '../contexts/ThemeContext';

function App() {
  const [theme, setTheme] = React.useState(localStorage.getItem("theme") || LIGHT_THEME);
  const toggleTheme = () => {
    const newTheme = theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME;
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  }

  const LayoutWrapper = ({ children }) => {
    const navigation = useNavigation();
    return (
      <Layout toggleTheme={toggleTheme}>
        {navigation.state === "loading" && <Loader theme={ThemeContextData[theme]} />}
        {navigation.state === "idle" && children}
      </Layout>
    );
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LayoutWrapper><HomePage /></LayoutWrapper>,
      errorElement: <LayoutWrapper><ErrorPage /></LayoutWrapper>,
    },
    {
      path: "/contact-us",
      element: <LayoutWrapper><ContactUsPage /></LayoutWrapper>
    },
    {
      path: "/programs",
      element: <LayoutWrapper><ProgramsPage /></LayoutWrapper>
    },
    {
      path: "/programs/:programDisplayId/:variantDisplayId",
      element: <LayoutWrapper><ProgramPage /></LayoutWrapper>
    },
    {
      path: "/about-us",
      element: <LayoutWrapper><AboutUsPage /></LayoutWrapper>
    },
    {
      path: "/products",
      element: <LayoutWrapper><ProductsPage /></LayoutWrapper>
    },
    {
      path: "/terms-and-conditions",
      element: <LayoutWrapper> <TermsAndConditionsPage /></LayoutWrapper>
    },
    {
      path: "/error",
      element: <LayoutWrapper><ErrorPage /></LayoutWrapper>
    },
    {
      path: "/sign-in",
      element: <LayoutWrapper><SignInPage /></LayoutWrapper>
    },
    {
      path: "/sign-up",
      element: <LayoutWrapper><SignUpPage /></LayoutWrapper>
    },
    {
      path: "/blogs",
      element: <LayoutWrapper><BlogsPage /></LayoutWrapper>
    },
    {
      path: "/blogs/:blogId",
      element: <LayoutWrapper><BlogPage /></LayoutWrapper>
    },
    {
      path: "/courses",
      element: <LayoutWrapper><CoursesPage /></LayoutWrapper>
    },
    {
      path: "/courses/:courseId",
      element: <LayoutWrapper><CoursePage /></LayoutWrapper>
    },
    {
      path: "/profile",
      element: <LayoutWrapper><ProfilePage /></LayoutWrapper>
    },
    {
      path: "/cart",
      element: <LayoutWrapper><CartPage /></LayoutWrapper>
    }
  ]);

  return (
    <React.StrictMode>
      <AuthProvider>
        <AppContext.Provider value={AppContextData}>
          <ThemeContext.Provider value={theme}>
            <RouterProvider router={router} />
          </ThemeContext.Provider>
        </AppContext.Provider>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
