import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { ThemeContext, ThemeContextData } from '../../contexts/ThemeContext';
import Loader from '../../components/Loader';

const ProfilePage = () => {
    const { user, loading } = useContext(AuthContext);
    const themeContext = useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];

    return (
        loading ? <Loader theme={theme} /> :
            <div className={`${theme.background} ${theme.textNormal} min-h-screen py-12 px-4 sm:px-6 lg:px-8`}>
                <div className={`max-w-md mx-auto ${theme.backgroundSecondary} rounded-lg shadow-md p-8`}>
                    <h2 className={`${theme.textFocus} text-3xl font-extrabold text-center mb-6`}>
                        User Profile
                    </h2>
                    <div className="space-y-6">
                        <div>
                            <p className="text-sm font-medium mb-1">Name</p>
                            <p className={`${theme.textFocus} text-lg`}>{user.name}</p>
                        </div>
                        <div>
                            <p className="text-sm font-medium mb-1">Email</p>
                            <p className={`${theme.textFocus} text-lg`}>{user.email}</p>
                        </div>
                        <div>
                            <p className="text-sm font-medium mb-1">Mobile Number</p>
                            <p className={`${theme.textFocus} text-lg`}>{user.mobileNumber}</p>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default ProfilePage;
