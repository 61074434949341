import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext, ThemeContextData } from '../../contexts/ThemeContext';
import { getCourses } from '../../utils/api';
import { FaClock, FaUser, FaStar } from 'react-icons/fa';
import Loader from '../../components/Loader';

const CoursesPage = () => {
    const themeContext = useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchCourses = async () => {
        try {
          const coursesData = await getCourses();
          setCourses(coursesData);
          setLoading(false);
        } catch (err) {
          setError('Failed to fetch courses. Please try again later.');
          setLoading(false);
        }
      };
  
      fetchCourses();
    }, []);
  
    if (loading) {
      return (
        <Loader theme={theme}/>
      );
    }
  
    if (error) {
      return (
        <div className={`${theme.background} ${theme.textNormal} min-h-screen flex items-center justify-center`}>
          <div className="text-center">
            <h2 className={`${theme.textFocus} text-2xl font-bold mb-4`}>Oops! Something went wrong.</h2>
            <p className={`${theme.textNormal} mb-4`}>{error}</p>
            <button 
              onClick={() => window.location.reload()} 
              className={`${theme.buttonPrimary} px-4 py-2 rounded`}
            >
              Try Again
            </button>
          </div>
        </div>
      );
    }
  
    return (
      <div className={`${theme.background} ${theme.textNormal} min-h-screen py-12`}>
        <div className="container mx-auto px-4">
          <h1 className={`${theme.textFocus} text-4xl font-bold mb-8 text-center`}>Explore Our Courses</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {courses.map((course) => (
              <Link
                key={course._id}
                to={`/courses/${course._id}`}
                className={`${theme.backgroundSecondary} rounded-lg shadow-md overflow-hidden transition duration-300 hover:shadow-lg transform hover:-translate-y-1`}
              >
                <div className="relative pb-48 overflow-hidden">
                  <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src={course.thumbnail || 'https://via.placeholder.com/400x300'}
                    alt={course.title}
                  />
                </div>
                <div className="p-6">
                  <h2 className={`${theme.textFocus} text-xl font-semibold mb-2`}>{course.title}</h2>
                  <p className={`${theme.textNormal} mb-4 line-clamp-2`}>{course.description}</p>
                  <div className="flex items-center mb-4">
                    <FaUser className={`${theme.textNormal} mr-2`} />
                    <span className={`${theme.textNormal}`}>{course.instructor}</span>
                  </div>
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center">
                      <FaClock className={`${theme.textNormal} mr-2`} />
                      <span className={`${theme.textNormal}`}>{`${course.courseValidity/86400} Days` || 'N/A'}</span>
                    </div>
                    <div className="flex items-center">
                      <FaStar className="text-yellow-400 mr-1" />
                      <span className={`${theme.textNormal}`}>{course.averageRating || '10/10'}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className={`${theme.textFocus} text-lg font-bold`}>₹{course.price.toFixed(2)}</span>
                    <span className={`${theme.textNormalHover} font-medium`}>View Course →</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
};

export default CoursesPage;
