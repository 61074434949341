import React, { useState, useEffect } from 'react';
import { ThemeContext, ThemeContextData } from '../contexts/ThemeContext';
import { FaChevronLeft, FaChevronRight, FaBook, FaChevronDown, FaLock } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const Sidebar = ({ course, currentSection, currentSubsection, onSubsectionClick, isOpen, setIsOpen, hasPurchased }) => {
  const themeContext = React.useContext(ThemeContext);
  const theme = ThemeContextData[themeContext];
  const [isMobile, setIsMobile] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  
  useEffect(() => {
    // Initialize all sections as expanded
    const initialExpandedState = course.sections.reduce((acc, _, index) => {
      acc[index] = true;
      return acc;
    }, {});
    setExpandedSections(initialExpandedState);
  }, [course]);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setExpandedSections(prev => ({
      ...prev,
      [currentSection]: true
    }));
  }, [currentSection]);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const toggleSection = (sectionIndex) => {
    if (!isOpen) {
      setIsOpen(true);
      setExpandedSections(prev => ({
        ...prev,
        [sectionIndex]: true
      }));
    } else {
      setExpandedSections(prev => ({
        ...prev,
        [sectionIndex]: !prev[sectionIndex]
      }));
    }
  };

  return (
    <motion.div 
      className={`h-full transition-all duration-300 ease-in-out ${
        isMobile ? 'absolute z-10' : 'relative'
      } ${theme.backgroundSecondary} shadow-lg overflow-hidden rounded-r-3xl`}
      initial={false}
      animate={{ 
        width: isOpen ? (isMobile ? '80%' : 320) : (isMobile ? 80 : 80)
      }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className="flex flex-col h-full">
        <div className={`flex items-center justify-between p-4 ${theme.backgroundTertiary} rounded-br-3xl`}>
          {isOpen && <h2 className={`${theme.textFocus} text-lg font-semibold truncate`}>{course.title}</h2>}
          <button
            onClick={toggleSidebar}
            className={`${theme.textFocus} p-2 rounded-full hover:bg-opacity-20 hover:bg-gray-600 transition-all duration-200`}
          >
            {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
          </button>
        </div>
        <div className="overflow-y-auto flex-grow p-2">
          {course.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-2 rounded-xl overflow-hidden">
              <button
                onClick={() => toggleSection(sectionIndex)}
                className={`w-full px-4 py-2 ${theme.textNormal} font-medium flex items-center justify-between rounded-xl ${
                  !isOpen && currentSection === sectionIndex ? `${theme.backgroundTertiary} ${theme.textFocus}` : ''
                }`}
              >
                {isOpen ? (
                  <>
                    <span>{section.title}</span>
                    <FaChevronDown className={`transform transition-transform ${expandedSections[sectionIndex] ? 'rotate-180' : ''}`} />
                  </>
                ) : (
                  <span className="text-center w-full">{sectionIndex + 1}</span>
                )}
              </button>
              <AnimatePresence>
                {(isOpen && expandedSections[sectionIndex]) && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="mt-1 rounded-xl overflow-hidden"
                  >
                    {section.subsections.map((subsection, subsectionIndex) => (
                      <motion.div
                        key={subsectionIndex}
                        className={`px-6 py-2 cursor-pointer transition-colors duration-200 flex items-center justify-between ${
                          currentSection === sectionIndex && currentSubsection === subsectionIndex
                            ? `${theme.backgroundTertiary} ${theme.textFocus}`
                            : `hover:${theme.backgroundTertiary} ${theme.textNormal}`
                        }`}
                        onClick={() => onSubsectionClick(sectionIndex, subsectionIndex)}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        {isOpen ? (
                          <>
                            <span>{subsection.title}</span>
                            {subsection.isLocked && !hasPurchased && <FaLock className="ml-2" />}
                          </>
                        ) : (
                          <div className="flex justify-center">
                            {subsection.isLocked && !hasPurchased ? <FaLock /> : <FaBook />}
                          </div>
                        )}
                      </motion.div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Sidebar;
