// src/components/PurchaseCourseModal.js
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaLock, FaTimes } from 'react-icons/fa';

const PurchaseCourseModal = ({ isOpen, onClose, onPurchase, course, theme }) => {
    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
                onClick={onClose}
            >
                <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 50, opacity: 0 }}
                    className={`${theme.backgroundSecondary} w-full max-w-md p-6 rounded-lg shadow-xl`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="flex justify-between items-center mb-4">
                        <h2 className={`${theme.textFocus} text-2xl font-bold`}>Unlock Full Access</h2>
                        <button onClick={onClose} className={`${theme.textNormal} hover:${theme.textFocus}`}>
                            <FaTimes />
                        </button>
                    </div>
                    <div className="mb-6">
                        <FaLock className={`${theme.textFocus} text-4xl mb-4 mx-auto`} />
                        <p className={`${theme.textNormal} text-center`}>
                            Get full access to "{course.title}" and unlock all its content.
                        </p>
                    </div>
                    <div className={`${theme.textFocus} text-xl font-bold text-center mb-6`}>
                        ₹{course.price}
                    </div>
                    <a class="flex items-center mt-auto text-white bg-brand-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-brand-600 rounded font-bold" onClick={onPurchase} href="javascript:void(0)" style={{cursor: "default;"}}>Purchase Course
                    </a>
                    <p className={`${theme.textNormal} text-sm text-center mt-4`}>
                        By purchasing, you agree to our Terms of Service and Privacy Policy.
                    </p>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

export default PurchaseCourseModal;
