// src/utils/api.js
import axios from 'axios';

const API_BASE_URL = 'https://grip-it-backend.onrender.com/api';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token);
  } else {
    delete api.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

// Initialize auth token from localStorage
const token = localStorage.getItem('token');
if (token) {
  setAuthToken(token);
}

export const signUp = async (userData) => {
  try {
    const response = await api.post('/auth/signup', userData);
    setAuthToken(response.data.token);
    return response.data;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error.response ? error.response.data : error;
  }
};

export const signIn = async (credentials) => {
  try {
    const response = await api.post('/auth/login', credentials);
    setAuthToken(response.data.token);
    return response.data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error.response ? error.response.data : error;
  }
};

export const signOut = () => {
  setAuthToken(null);
};

export const getCourses = async () => {
  try {
    const response = await api.get('/courses');
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const getCourseById = async (courseId) => {
  try {
    const response = await api.get(`/courses/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching course:', error);
    throw error;
  }
};

export const getUserPurchasedCourses = async () => {
  try {
    const response = await api.get('/courses/user/purchased');
    return response.data;
  } catch (error) {
    console.error('Error fetching user\'s purchased courses:', error);
    throw error;
  }
};

export const addToCart = async (courseId) => {
  try {
    const response = await api.post('/cart/add', { itemId: courseId, itemType: 'Course' });
    return response.data;
  } catch (error) {
    console.error('Error adding to cart:', error);
    throw error;
  }
};

export const getCart = async () => {
  try {
    const response = await api.get('/cart');
    return response.data;
  } catch (error) {
    console.error('Error fetching cart:', error);
    throw error;
  }
};

export const createOrder = async (amount) => {
  try {
    const response = await api.post('/payments/create-order', { amount });
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const verifyPayment = async (paymentData) => {
  try {
    const response = await api.post('/payments/verify', paymentData);
    return response.data;
  } catch (error) {
    console.error('Error verifying payment:', error);
    throw error;
  }
};

export const updateCartItemQuantity = async (itemId, quantity) => {
  try {
    const response = await api.patch(`/cart/items/${itemId}`, { quantity });
    return response.data;
  } catch (error) {
    console.error('Error updating cart item quantity:', error);
    throw error;
  }
};

export const removeCartItem = async (itemId) => {
  try {
    const response = await api.delete(`/cart/items/${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error removing cart item:', error);
    throw error;
  }
};


export default api;
