import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeContext, ThemeContextData } from '../../contexts/ThemeContext';
import { AuthContext } from '../../contexts/AuthContext';
import { signUp } from '../../utils/api';
import { FaUser, FaEnvelope, FaMobileAlt, FaLock, FaUserPlus } from 'react-icons/fa';

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const themeContext = useContext(ThemeContext);
  const theme = ThemeContextData[themeContext];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await signUp(formData);
      login(response.user, response.token);
      navigate('/');
    } catch (err) {
      setError(err.message || 'An error occurred during sign up');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`${theme.background} ${theme.textNormal} min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8`}>
      <div className={`${theme.backgroundSecondary} p-8 rounded-lg shadow-lg w-full max-w-md`}>
        <div className="text-center mb-8">
          <h2 className={`${theme.textFocus} text-3xl font-extrabold`}>Create an Account</h2>
          <p className={`mt-2 ${theme.textNormal}`}>Join us today</p>
        </div>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="sr-only">Name</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaUser className={`${theme.textNormal}`} />
              </div>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className={`${theme.backgroundTertiary} ${theme.textNormal} w-full pl-10 pr-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500`}
                placeholder="Full Name"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="sr-only">Email</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaEnvelope className={`${theme.textNormal}`} />
              </div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className={`${theme.backgroundTertiary} ${theme.textNormal} w-full pl-10 pr-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500`}
                placeholder="Email address"
              />
            </div>
          </div>
          <div>
            <label htmlFor="mobileNumber" className="sr-only">Mobile Number</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaMobileAlt className={`${theme.textNormal}`} />
              </div>
              <input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
                className={`${theme.backgroundTertiary} ${theme.textNormal} w-full pl-10 pr-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500`}
                placeholder="Mobile Number"
              />
            </div>
          </div>
          <div>
            <label htmlFor="password" className="sr-only">Password</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaLock className={`${theme.textNormal}`} />
              </div>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className={`${theme.backgroundTertiary} ${theme.textNormal} w-full pl-10 pr-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500`}
                placeholder="Password"
              />
            </div>
          </div>
          <div>
            <button 
              type="submit" 
              className={`${theme.buttonPrimary} w-full py-2 px-4 rounded-md flex items-center justify-center`}
              disabled={loading}
            >
              {loading ? (
                <span className={`flex items-center ${theme.textNormal}`}>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Signing Up...
                </span>
              ) : (
                <span className={`flex items-center ${theme.textNormal}`}>
                  <FaUserPlus className="mr-2" />
                  Sign Up
                </span>
              )}
            </button>
          </div>
        </form>
        <div className="mt-6 text-center">
          <p className={`${theme.textNormal}`}>
            Already have an account?{' '}
            <Link to="/sign-in" className={`${theme.textNormalHover} font-medium hover:underline`}>
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
