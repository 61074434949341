import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ThemeContext, ThemeContextData } from '../../contexts/ThemeContext';
import { AuthContext } from '../../contexts/AuthContext';
import Sidebar from '../../components/Sidebar';
import CourseContent from '../../components/CourseContent';
import { getCourseById, getUserPurchasedCourses, addToCart } from '../../utils/api';
import Loader from '../../components/Loader';
import PurchaseCourseModal from '../../components/PurchaseCourseModal';

const CoursePage = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const themeContext = useContext(ThemeContext);
  const theme = ThemeContextData[themeContext];
  const { user, authLoading } = useContext(AuthContext);
  const [course, setCourse] = useState(null);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentSubsection, setCurrentSubsection] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [hasPurchased, setHasPurchased] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [courseData, purchasedCourses] = await Promise.all([
          getCourseById(courseId),
          user ? getUserPurchasedCourses() : []
        ]);
        setHasPurchased(purchasedCourses.some(course => course._id === courseId));
        setCourse(courseData);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch course data. Please try again later.');
        setLoading(false);
      }
    };
    if(!authLoading) fetchData();
    
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 768;
      setIsMobile(newIsMobile);
      setIsSidebarOpen(!newIsMobile);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [courseId, user, authLoading]);

  const handleSubsectionClick = (sectionIndex, subsectionIndex) => {
    const subsection = course.sections[sectionIndex].subsections[subsectionIndex];
    if (subsection.isLocked && !hasPurchased) {
      setIsPurchaseModalOpen(true);
    } else {
      setCurrentSection(sectionIndex);
      setCurrentSubsection(subsectionIndex);
    }
  };

  const handlePurchase = async () => {
    try {
      setIsPurchaseModalOpen(false);
      setLoading(true);
      await addToCart(course._id);
      setLoading(false);
      navigate('/cart');
    } catch (error) {
      setLoading(false);
      console.error('Error adding course to cart:', error);
      if(error?.response?.data?.error){
        navigate('/cart');
      }
      // Handle error (e.g., show error message to user)
    }
  };

  const renderCourseDetails = () => {
    if (hasPurchased) return null;

    return (
      <div className={`${theme.backgroundSecondary} p-6 mb-6 rounded-lg shadow-md`}>
        <h1 className={`${theme.textPrimary} text-3xl font-bold mb-4`}>{course.title}</h1>
        <p className={`${theme.textSecondary} mb-4`}>{course.description}</p>
        <div className="flex justify-between items-center mb-4">
          <span className={`${theme.textPrimary} text-2xl font-bold`}>Investment: ₹{course.price}</span>
          <button
            onClick={() => handlePurchase()}
            className="inline-block px-6 py-3 mt-auto w-full md:w-auto text-center text-lg font-semibold text-white bg-brand-500 hover:bg-brand-600 rounded-md focus:outline-none"
          >
            Purchase Course
          </button>
        </div>
        <div className={`${theme.textSecondary}`}>
          <p>Instructor: {course.instructor}</p>
          <p>Validity: {course.courseValidity/86400} Days</p>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className={`${theme.background} ${theme.textNormal} min-h-screen flex items-center justify-center`}>
        <Loader theme={theme} />
      </div>
    );
  }

  if (error) {
    return <div className={`${theme.background} ${theme.textNormal} min-h-screen flex items-center justify-center`}>{error}</div>;
  }

  if (!course) {
    return <div className={`${theme.background} ${theme.textNormal} min-h-screen flex items-center justify-center`}>Course not found</div>;
  }

  return (
    <div className={`${theme.background} ${theme.textNormal} flex flex-col min-h-screen`}>
      <div className="flex flex-1 overflow-hidden relative">
        <Sidebar
          course={course}
          currentSection={currentSection}
          currentSubsection={currentSubsection}
          onSubsectionClick={handleSubsectionClick}
          isOpen={isSidebarOpen}
          setIsOpen={setIsSidebarOpen}
          hasPurchased={hasPurchased}
        />
        <main className={`flex-1 overflow-y-auto transition-all duration-300 ${
          isSidebarOpen ? 'ml-0' : 'ml-20'
        }`}>
          <div className="min-h-full p-4 md:p-8">
            <div className="w-full max-w-6xl mx-auto">
              {renderCourseDetails()}
              <CourseContent
                section={course.sections[currentSection]}
                subsection={course.sections[currentSection].subsections[currentSubsection]}
                isLocked={!hasPurchased && course.sections[currentSection].subsections[currentSubsection].isLocked}
                user={user}
              />
            </div>
          </div>
        </main>
      </div>
      <PurchaseCourseModal
        isOpen={isPurchaseModalOpen}
        onClose={() => setIsPurchaseModalOpen(false)}
        onPurchase={handlePurchase}
        course={course}
        theme={theme}
      />
    </div>
  );
};

export default CoursePage;
